import React, { useState } from "react"
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import ReCAPTCHA from "react-google-recaptcha"

const ContactPage = ({
  data: {
    site
  },
}) => {

	const [submitActive, setSubmitActive] = useState(false);
	const [recaptchaError, setRecaptchaError] = useState(false);

	const onChange = (response) => {
		if( response ) {
			setSubmitActive(true);
			setRecaptchaError(false);
		}
		else {
			setSubmitActive(false);
			setRecaptchaError(true);
		}
	}

  return (
    <Layout>
      <Helmet>
        <title>Contact — {site.siteMetadata.title}</title>
        <meta name="description" content={"Contact Us " + site.siteMetadata.description} />
      </Helmet>
      <div className="-contact">
        <div>
          <form className="form-container" action="/" method="post" data-netlify="true" data-netlify-recaptcha="true" name="contact" >
            <div>
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" required/>
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input type="email" name="email" id="email" required/>
            </div>
            <div>
              <label htmlFor="subject">Subject</label>
              <input type="text" name="subject" id="subject" required/>
            </div>
            <div>
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" required></textarea>
            </div>
						<ReCAPTCHA
							sitekey="6LfYieYfAAAAAEDBlVhArmpJYevsRoFAr5RHuoJp"
							onChange={onChange}	
						/>
						<p
							style={{color: "red", fontWeight: "bold"}}
							className={(recaptchaError ? "" : "hidden")}
						>
							reCAPTCHA error. Please try again.
						</p>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
              <input
								type="submit"
								className={`button -primary ${( submitActive ? "active" : "disabled" )}`}
								style={{marginRight: 0}}
								disabled={!submitActive}
							/>
            </div>
            <input type="hidden" name="form-name" value="contact" />
          </form>
        </div>
      </div>
    </Layout>
  )
}
export default ContactPage
export const pageQuery = graphql`
  query ContactPageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`